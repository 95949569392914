import CMSLayout from '@/layout/CMSLayout.vue'

export const AccountInfoRouter = {
  path: '/',
  component: CMSLayout,
  children: [
    {
      path: 'account-detail',
      name: 'AccountInfoPage',
      // lazy load
      component: () => import('../components/account-detail.vue'),
      meta: {
        onlyWhenLoggedOut: true,
        public: true,
        title: 'Account'
      }
    },
    {
      path: 'account-payment',
      name: 'AccountPaymentPage',
      // lazy load
      component: () => import('../components/account-payment.vue'),
      meta: {
        onlyWhenLoggedOut: true,
        public: true,
        title: 'My Cards'
      }
    }
  ]
}
